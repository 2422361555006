import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ProfileWidget from "./ProfileWidget";
import { APP_ENV } from "../../config";
import { logout } from "../../utils";
import { ModalConfirm } from "tamtam-components";
import _ from "../../i18n";

const Profile = ({ contact, scrollToTop }) => {
  const [isWidgetLoaded, setIsWidgetLoaded] = useState(false);
  const auth = useSelector(state => state.auth);
  const { token, user, scope } = auth;
  const language = useSelector(state => state.params.language);
  const [isOpen, setIsOpen] = useState(false);
  const [shouldRender, setShouldRender] = useState(false);
  const selectedOrganizationId = auth?.navCommunity?.id;
  const userId = auth?.user?.id;

  useEffect(
    () => {
      if (scope?.includes("_ls")) {
        setShouldRender(false);
        setIsOpen(true);
      } else {
        setShouldRender(true);
      }
      const script = document.createElement("script");
      let userSelected = contact ?? user;

      if (userSelected) {
        script.src = `https://tamtam.s3.eu-west-1.amazonaws.com/cdn/profile/${
          APP_ENV === "rc2"
            ? "staging"
            : APP_ENV === "v2"
              ? "production"
              : APP_ENV
        }/static/js/profileWidget.js`;
        script.async = true;
        script.onload = () => {
          setIsWidgetLoaded(true);
        };

        document.body.appendChild(script);
      }

      return () => {
        if (userSelected) {
          document.body.removeChild(script);
        }
      };
    },
    [user, contact],
  );

  if (isWidgetLoaded) {
    let userSelected = contact ?? user;

    return (
      <>
        {!shouldRender ? (
          <ModalConfirm
            type="logout"
            isOpen={isOpen}
            onCancel={() => {
              setIsOpen(false);
              window.history.back();
            }}
            onConfirm={() => logout()}
            inProcess={false}
            title={_("confirm_logout_title")}
            text={_("confirm_logout_text")}
            labelNo={_("cancel")}
            labelYes={_("connection")}
            labelError={_("error")}
          />
        ) : (
          <>
            <link
              href={`https://tamtam.s3.eu-west-1.amazonaws.com/cdn/profile/${
                APP_ENV === "rc2"
                  ? "staging"
                  : APP_ENV === "v2"
                    ? "production"
                    : APP_ENV
              }/static/css/profileWidget.css`}
              rel="stylesheet"
            />
            <ProfileWidget
              token={token}
              lng={language}
              userId={userSelected.id}
              isContact={!!contact}
              onScrollToTop={scrollToTop}
              selectedOrganization={selectedOrganizationId}
              updatedByUser={userId}
            /></>)}
      </>
    );
  }
  return null;
};

export default Profile;
