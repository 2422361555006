import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ModalConfirm } from "tamtam-components";
import moment from "moment";
import { toast } from "react-toastify";
import chunk from "lodash.chunk";

import { formatPhoneNumberIntl } from "react-phone-number-input";
import Tooltip from "@mui/material/Tooltip";

import {
  getMemberships,
  addMemberShipOrder,
  addBulkMemberShipOrder,
} from "../../../../api";
import _ from "../../../../i18n";
import { SELECT_STYLES } from "../../../../config";
import { formatUen, getDateLabel } from "../../../../utils";

import Pagination from "../../../common/Pagination";
import Loader from "../../../common/Loader";
import Button from "../../../common/Button";

import MemberOrderModal from "./MemberOrderModal";
import MemberStatOrderModal from "./MemberStatOrderModal";
import styles from "./ManageMembership.module.scss";

const currentYear =
  moment().month() == 11 ? moment().year() + 1 : moment().year();

const yearsOptions = [{ value: currentYear, label: currentYear }];

for (let i = currentYear - 1; i >= 2020; i--) {
  yearsOptions.push({ value: i, label: i });
}

const CompareList = ({
  isLoading,
  data,
  memberships,
  formulas,
  formulaOptions,
  currentPage,
  setCurrentPage,
  refreshList,
  filters,
}) => {
  const auth = useSelector((state) => state.auth);
  const { token } = auth;
  const language = useSelector((state) => state.params.language);
  const [showBillingModal, setShowBillingModal] = useState(false);
  const [showStatBillingModal, setShowStatBillingModal] = useState(false);
  const [currentMemberOrder, setCurrentMemberOrder] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [noLimitSelected, setNoLimitSelected] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [progressValue, setProgressValue] = useState(0);
  const [ignoreSentMember, setIgnoreSentMember] = useState(false);

  const LIMIT = 20;

  const selectMember = (item) => {
    if (selectedIds.includes(item.user.id)) {
      setSelectedIds([
        ...selectedIds.filter((element) => element !== item.user.id),
      ]);
      if (noLimitSelected) {
        setNoLimitSelected(false);
      }
    } else {
      setSelectedIds([...selectedIds, item.user.id]);
    }
  };

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedIds([]);
      setNoLimitSelected(false);
    } else {
      if (data?.data) {
        setSelectedIds(data.data.map((item) => item.user.id));
      }
    }
    setSelectAll(!selectAll);
  };

  const getAllUserData = async () => {
    try {
      const formulaIds = filters.formulaFilter
        ? formulaFilter.map((i) => i.value)
        : [];
      let exYear = filters.excludeYear ? filters.excludeYear.value : null;
      let prevYear =
        filters.previousYear.length > 0
          ? filters.previousYear.map((i) => i.value)
          : null;
      const response = await getMemberships({
        token,
        status: filters.status.value,
        page: 0,
        limit: 0,
        sort: {
          property: "createdAt",
          dir: "desc",
        },
        year: filters.year.value,
        excludeYear: exYear,
        previousYear: prevYear,
        formulaIds,
        source: filters.source ? filters.source.value : null,
        search: filters.search,
        without_associates: true,
      });
      return response.data.data;
    } catch (e) {
      return [];
    }
  };

  const sendBulkOrder = async () => {
    let result = [];
    setShowConfirmModal(false);
    setIsSaving(true);

    if (noLimitSelected) {
      result = await getAllUserData();
    } else {
      result = data.data.filter((i) => selectedIds.includes(i.user.id));
    }

    let tab = [];
    result.forEach((i) => {
      if (!ignoreSentMember || (ignoreSentMember && !i.orderSent)) {
        let formattedUen = "";
        if (i.billingCompanyNumber) {
          formattedUen = i.billingCompanyNumber.includes("BE")
            ? formatUen(i.billingCompanyNumber)
            : formatUen("BE" + i.billingCompanyNumber);
        }

        tab.push({
          language,
          memberShip: i.data,
          uen: formattedUen ? formattedUen : "",
          organization: i.billingOrganization,
          address: i.billingStreet,
          postalCode: i.billingPostalCode,
          city: i.billingCountry,
          billingSubjectToVAT: i.billingSubjectToVAT ? 1 : 0,
          user: i.user.id,
          orderFromOECCBB: 1,
          source: "UA",
          orderSent: i.orderSent ? true : false,
        });
      }
    });
    let hasError = false;
    const chunked = chunk(tab, 20);
    for (let i = 0; i < chunked.length; i++) {
      setProgressValue(Math.floor((i / chunked.length) * 100));

      const d = new Date();
      let time = d.getTime();
      var file = new File(
        [
          JSON.stringify(chunked[i], (key, value) =>
            typeof value === "string" ? encodeURIComponent(value) : value
          ),
        ],
        `data-${time}.txt`,
        {
          type: "text/plain",
        }
      );

      try {
        const resp = await addBulkMemberShipOrder(token, file);
      } catch (error) {
        hasError = true;
      }
    }
    setIsSaving(false);
    setProgressValue(0);
    setSelectedIds([]);
    setNoLimitSelected(false);
    refreshList();
    toast.success("Envoi résussi");
  };

  const handleSendOrder = (item) => {
    setCurrentMemberOrder(item);
    setShowBillingModal(true);
  };

  const handleMemberShipOrder = (data) => {
    setIsSaving(true);
    let memberShip = JSON.stringify(data.data);
    addMemberShipOrder({
      token,
      language,
      memberShip,
      uen: data.uen,
      organization: data.organization,
      address: data.address,
      postalCode: data.postalCode,
      city: data.city,
      subjectToVAT: data.subjectToVAT,
      user: data.user.id,
      orderFromOECCBB: true,
    })
      .then((response) => {
        if (response.data.data) {
          toast.success(_("successfully_sent"));
          setIsSaving(false);
          refreshList();
        }
      })
      .catch((e) => {
        setIsSaving(false);
        toast.error(_("error_add_order"));
      });
  };

  return (
    <>
      {isSaving && <div className="lmask"></div>}

      {selectedIds.length > 0 && (
        <div className={styles.orderAction}>
          {isSaving && (
            <div className={styles.progress}>
              <div>{progressValue}%</div>
              <div className={styles.progress_bar}>
                <span
                  className={styles.progress_value}
                  style={{ width: progressValue + "%" }}
                ></span>
              </div>
            </div>
          )}
          {!noLimitSelected && <div>{selectedIds.length} sélectionné(s)</div>}
          <Button
            size="sm"
            onClick={() => {
              if (selectedIds.length === 1) {
                const item = data.data.filter(
                  (i) => i.user.id === selectedIds[0]
                );
                if (item.length === 1) {
                  handleSendOrder(item[0]);
                }
              } else {
                setShowConfirmModal(true);
              }
            }}
          >
            Envoyer les bons de commandes
          </Button>
          <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
            <input
              id="ignoreBDC"
              type="checkbox"
              checked={ignoreSentMember}
              onClick={() => setIgnoreSentMember(!ignoreSentMember)}
            />{" "}
            <label for="ignoreBDC">
              Ignorer les membres ayant reçu un email
            </label>
          </div>
        </div>
      )}
      <div className={styles.tableContainer}>
        <table className={`${styles.table} unstriped`}>
          <thead>
            <tr>
              <th>
                <input
                  type="checkbox"
                  checked={selectAll}
                  onClick={() => handleSelectAll()}
                />
              </th>
              <th width="40%" className={styles.nowrap}>
                <span>Formule</span>
              </th>
              <th width="20%">Utilisateur</th>
              <th width="20%">Email</th>
              <th>Téléphone</th>
              <th>Statut</th>
              <th>Date</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <tr>
                <td colSpan={8} className={styles.centerCell}>
                  <Loader
                    style={{
                      height: "10px",
                    }}
                    color={"#B2BCC6"}
                  />
                </td>
              </tr>
            ) : data?.data && data.data.length > 0 ? (
              <>
                {selectAll && (
                  <tr className={styles.selectRowAction}>
                    <td colSpan={7}>
                      {noLimitSelected
                        ? `Les ${data.nbResult} membres sont sélectionnés.`
                        : `Les ${selectedIds.length} membres correspondant à la limite sont sélectionnés.`}{" "}
                      {selectedIds.length < data.nbResult &&
                        (!noLimitSelected ? (
                          <span
                            className={styles.selectAction}
                            onClick={() => setNoLimitSelected(true)}
                          >
                            Sélectionnez tous les {data.nbResult} membres
                          </span>
                        ) : (
                          <span
                            className={styles.selectAction}
                            onClick={() => setNoLimitSelected(false)}
                          >
                            Effacer la sélection
                          </span>
                        ))}
                    </td>
                  </tr>
                )}
                {data.data.map((item) => {
                  let orderTooltip = [
                    <h3 className={styles.toolTitle}>
                      Bon de commande envoyé le:{" "}
                      {getDateLabel(item.orderDate).split(",")[0]}
                    </h3>,
                  ];
                  if (item.orderSent) {
                    let lines = [];
                    item.orderData.forEach((row) =>
                      lines.push(
                        <li>
                          {memberships[row.membersShip].name +
                            " " +
                            formulas[row.formula].name}
                        </li>
                      )
                    );
                    orderTooltip.push(
                      <ul className={styles.toolLines}>{lines}</ul>
                    );
                  }

                  return (
                    <tr key={item.id}>
                      <td>
                        <input
                          type="checkbox"
                          checked={selectedIds.includes(item.user.id)}
                          onClick={() => selectMember(item)}
                        />
                      </td>
                      <td>
                        {item.data.map((row) => (
                          <p className={styles.btmBorder}>
                            {memberships[row.membersShip].name +
                              " " +
                              formulas[row.formula].name}
                          </p>
                        ))}
                      </td>
                      {/* <td>
                    {item.data.map((row) => (
                      <p className={styles.btmBorder}>
                        {formulas[row.formula].name}
                      </p>
                    ))}
                  </td> */}
                      <td>
                        {item.user.firstName} {item.user.lastName}
                      </td>
                      <td>{item.user.mainEmail}</td>
                      <td className={styles.nowrap}>
                        {item.user.mainPhone
                          ? formatPhoneNumberIntl(item.user.mainPhone)
                          : "-"}
                      </td>
                      <td>
                        {_(item.status.toLowerCase())}
                        <br />
                        {item.year}
                      </td>
                      <td>{moment(item.createdAt).format("DD/MM/YYYY")}</td>

                      <td>
                        {item.orderSent ? (
                          <>
                            <Tooltip title={orderTooltip} arrow>
                              <span className={styles.successBtn}>
                                <i className="icon-ttp-check" />
                              </span>
                            </Tooltip>
                            <Button
                              variant="default"
                              style={{ marginTop: "5px" }}
                              onClick={() => {
                                setCurrentMemberOrder(item);
                                setShowStatBillingModal(true);
                              }}
                            >
                              <i className="icon-ttp-stats" />
                            </Button>
                          </>
                        ) : // <Button
                        //   // onClick={() => handleSendOrder(item)}
                        //   title="Bon de commande envoyé"
                        //   variant="send"
                        // >
                        //   <i className="icon-ttp-check" />
                        // </Button>

                        item.showBC ? (
                          <Button
                            onClick={() => handleSendOrder(item)}
                            title="Envoyer le bon de commande"
                          >
                            <i className="icon-ttp-plane" />
                          </Button>
                        ) : (
                          <></>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </>
            ) : (
              <tr>
                <td colSpan={8} className={styles.centerCell}>
                  Liste vide (aucune donnée ne répond aux filtres)
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {data?.nbResult && (
        <Pagination
          className="pagination-bar"
          currentPage={currentPage}
          totalCount={data.nbResult}
          pageSize={LIMIT}
          onPageChange={(page) => setCurrentPage(page)}
        />
      )}

      <MemberOrderModal
        isOpen={showBillingModal}
        closeModal={() => setShowBillingModal(false)}
        currentMemberOrder={currentMemberOrder}
        onSave={handleMemberShipOrder}
        formulaOptions={formulaOptions}
        SELECT_STYLES={SELECT_STYLES}
      />
      <ModalConfirm
        type="publish"
        isOpen={showConfirmModal}
        onCancel={() => {
          setIsSaving(false);
          setShowConfirmModal(false);
        }}
        onConfirm={() => sendBulkOrder()}
        title={"Confirmation"}
        text={"Voulez vous envoyer les bons de commande à liste sélectionnée ?"}
        labelNo={_("no")}
        labelYes={_("yes")}
        labelError={_("error")}
      />
      {showStatBillingModal && (
        <MemberStatOrderModal
          isOpen={showStatBillingModal}
          closeModal={() => setShowStatBillingModal(false)}
          currentMemberOrder={currentMemberOrder}
          token={token}
        />
      )}
    </>
  );
};

export default CompareList;
