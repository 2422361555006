import axios from "axios";

import { TTP_API_URL } from "../config";

export const getParentSite = ({ token, tag, type = "FIDUCIARY" }) => {
  const filter = [
    { property: "organization", value: null, operator: "null" },
    { property: "type", value: type, operator: "eq" },
    { property: "tag", value: tag, operator: "eq" },
  ];

  const fields = ["*"];

  let params = {
    access_token: token,
    filter: JSON.stringify(filter),
    fields: fields.join(","),
  };

  const requestUrl = `${TTP_API_URL}/organization/site`;

  return axios.get(requestUrl, {
    params,
  });
};

export const getSite = ({ token, organizationId, type = "FIDUCIARY" }) => {
  const filter = [
    { property: "organization", value: organizationId, operator: "eq" },
    { property: "type", value: type, operator: "eq" },
  ];

  const fields = ["*"];

  let params = {
    access_token: token,
    filter: JSON.stringify(filter),
    fields: fields.join(","),
  };

  const requestUrl = `${TTP_API_URL}/organization/site`;

  return axios.get(requestUrl, {
    params,
  });
};

export const saveSite = ({ token, organizationId, tag, domain, id }) => {
  const requestUrl = `${TTP_API_URL}/organization/site/add-organization-site`;

  var formData = new FormData();

  formData.append("access_token", token);
  formData.append("type", "FIDUCIARY");
  formData.append("organization", organizationId);
  formData.append("tag", tag);

  if (domain) {
    formData.append("domain", domain);
  }

  if (id) {
    formData.append("id", id);
  }

  return axios.post(requestUrl, formData);
};
