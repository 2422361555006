import React, { useEffect, useState } from "react";
import Modal from "react-modal";

import { getMembershipStatEmailOrder } from "../../../../api";
import { getDateLabel } from "../../../../utils";
import _ from "../../../../i18n";
import Button from "../../../common/Button";

import styles from "./MemberOrderModal.module.scss";

const customModalStyles = {
  content: {
    backgroundColor: "#fff",
    border: "none",
    overflow: "none",
    padding: 0,
    top: "50%",
    bottom: "unset",
    height: "50%",
    maxHeight: "600px",
    borderRadius: "5px",
    boxShadow: "0px 0px 10px rgba(41, 57, 77, 0.04)",
    zIndex: 999,
    left: "50%",
    transform: "translateX(-50%) translateY(-50%)",
    width: "50%",
    maxWidth: "900px",
  },
};

export default function MemberStatOrderModal({
  isOpen,
  closeModal,
  currentMemberOrder,
  token,
}) {
  const [isFetching, setIsFetching] = useState(false);
  const [stats, setStats] = useState(null);

  useEffect(() => {
    if (isOpen && currentMemberOrder) {
      setIsFetching(true);
      getMembershipStatEmailOrder({ token, id: currentMemberOrder.id })
        .then((response) => {
          setStats(response.data.data);
        })
        .catch((e) => {})
        .finally(() => {
          setIsFetching(false);
        });
    }
  }, [isOpen, currentMemberOrder]);

  const handleCloseModal = () => {
    closeModal();
  };

  if (!currentMemberOrder) {
    return null;
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleCloseModal}
      shouldCloseOnOverlayClick={false}
      style={customModalStyles}
      overlayClassName={styles.overlay}
      contentLabel="Tamtam payment"
    >
      <div className="rmodal">
        <div className={styles.close} onClick={handleCloseModal}>
          <i className="icon-ttp-close-outline"></i>
        </div>
        <div className={styles.wrapper}>
          <h3 className={styles.title}>
            Bon de commande envoyé à :{" "}
            {currentMemberOrder.user.firstName +
              " " +
              currentMemberOrder.user.lastName}
          </h3>

          {isFetching && <div className="lmask"></div>}

          <div className={styles.statRow}>
            Envoyé le:{" "}
            {getDateLabel(currentMemberOrder.orderDate).split(",")[0]}
          </div>
          <div className={styles.statRow}>
            Ouvert pour la dernière fois le:{" "}
            {stats?.openAt ? getDateLabel(stats.openAt).split(",")[0] : ""}
          </div>
          <div className={styles.statRow}>
            Cliqué le:{" "}
            {stats?.clickAt ? getDateLabel(stats.clickAt).split(",")[0] : ""}
          </div>
        </div>

        {/* <div className={styles.modalActions}>
          <Button variant="default" onClick={handleCloseModal}>
            {_("cancel")}
          </Button>
        </div> */}
      </div>
    </Modal>
  );
}
