import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { arrayMoveImmutable } from "array-move";

import { sortableContainer, sortableElement } from "react-sortable-hoc";
import styles from "../Portals.module.scss";
import Switch from "../../../../common/Switch/Switch";
import _ from "../../../../../i18n";
import { BlogApp, DefaultApp, WatchApp, NewsletterApp } from "../PortalApps";

const authorTypes = [
  { value: "ACCOUNTANT", label: _("accountant") },
  { value: "TAX_ADVICE", label: _("tax_advice") },
  { value: "LAWYER", label: _("lawyer") },
  { value: "NOTARY", label: _("notary") },
  { value: "CONSULTANT", label: _("consultant") },
  { value: "PROFESSOR", label: _("professor") },
  { value: "JOURNALIST", label: _("journalist") },
  { value: "OTHERS", label: _("others") },
];

const Collaborator = ({
  fiduciarySettings,
  setFiduciarySettings,
  portals,
  setPortals,
  handleCheckPortal,
  apps,
  setIsLoading,
  setPortalsData,
}) => {
  const fiduciary = useSelector((state) => state.folder.fiduciary);
  const lng = useSelector((state) => state.params.language);
  const dbCategories = useSelector((state) => state.categories.categories);
  const [categoryFilter, setCategoryFilter] = useState([]);
  const [initCategoryFilter, setInitCategoryFilter] = useState([]);
  const [authorTypesFilter, setAuthorTypesFilter] = useState(authorTypes);
  const [scopeFilter, setScopeFilter] = useState(["PUBLIC", "COLLABORATOR"]);
  const [sourceFilter, setSourceFilter] = useState(["FFF_LIBRARY"]);

  useEffect(() => {
    if (fiduciarySettings) {
      let tabCollaborator = [];
      let collaboratorAddedApps = [];
      if (fiduciarySettings.collaborator.enabled) {
        let dCollaborator = JSON.parse(
          JSON.stringify(fiduciarySettings.collaborator)
        );

        dCollaborator.config.apps.forEach((item, index) => {
          let app = apps.filter((i) => i.value === item.id)[0];
          if (app) {
            if (item.id === "BLOG") {
              if (item.categories && item.categories.length > 0) {
                // setInitCategoryFilter(item.categories);

                const nameAttr = `name${
                  lng.charAt(0).toUpperCase() + lng.slice(1)
                }`;
                const tabCategories = [];

                item.categories.forEach((i) => {
                  const tmp = dbCategories.filter((it) => it.id === i);
                  if (tmp && tmp.length === 1) {
                    tabCategories.push({ value: i, name: tmp[0][nameAttr] });
                  }
                });
                setCategoryFilter(tabCategories);
              }
              if (item.authorTypes && item.authorTypes.length > 0) {
                const tabTypes = [];
                item.authorTypes.forEach((i) => {
                  const tmp = authorTypes.filter((it) => it.value === i);
                  if (tmp && tmp.length === 1) {
                    tabTypes.push(tmp[0]);
                  }
                });
                setAuthorTypesFilter(tabTypes);
              }
              if (item.scope && item.scope.length > 0) {
                if (typeof item.scope[0] === "string") {
                  setScopeFilter(item.scope);
                }
              }
              if (item.source && item.source.length > 0) {
                setSourceFilter(item.source);
              }
              tabCollaborator.push({
                ...app,
                enabled: item.enabled,
                order: item.order,
                type: item.type ? item.type : app.type,
                relevance: item.relevance ? item.relevance : app.relevance,

                categories: item.categories ? item.categories : [],
                authorTypes: item.authorTypes ? item.authorTypes : [],
                scope: item.scope ? item.scope : ["PUBLIC"],
                source: item.source ? item.source : ["FFF_LIBRARY"],
              });
            } else {
              tabCollaborator.push({
                ...app,
                enabled: item.enabled,
                order: item.order,
              });
            }
            collaboratorAddedApps.push(app.value);
          }
        });
      }
      apps.forEach((item, index) => {
        if (item && !collaboratorAddedApps.includes(item.value)) {
          let add = true;
          if (item.value === "POWERTEAM" && fiduciary.id !== 4) {
            add = false;
          }
          if (add) {
            tabCollaborator.push({
              ...item,
              enabled: false,
              order: index + 1,
            });
          }
        }
      });
      let data = fiduciarySettings;
      data.collaborator.config.apps = tabCollaborator;
      setPortals({
        ...data,
      });
      setIsLoading(false);
    } else {
      let tab = [];
      apps.forEach((item, index) => {
        tab.push({
          ...item,
          enabled: false,
          order: index + 1,
        });
      });
      setFiduciarySettings({
        ...portals,
        collaborator: {
          ...portals.collaborator,
          config: {
            ...portals.collaborator.config,
            apps: JSON.parse(JSON.stringify(tab)),
          },
        },
      });
      setPortals({
        ...portals,
        collaborator: {
          ...portals.collaborator,
          config: {
            ...portals.collaborator.config,
            apps: JSON.parse(JSON.stringify(tab)),
          },
        },
      });
      setIsLoading(false);
    }
  }, [fiduciarySettings]);

  useEffect(() => {
    let dataCollaborator = JSON.parse(JSON.stringify(portals.collaborator));

    dataCollaborator.config.apps = dataCollaborator.config.apps.map((item) => {
      if (item.value === "BLOG") {
        return {
          id: item.value,
          enabled: item.enabled,
          order: item.order,
          type: item.type,
          relevance: item.relevance,
          categories: categoryFilter
            ? categoryFilter.map((item) => item.id)
            : [],
          authorTypes: authorTypesFilter.map((item) => item.value),
          scope: scopeFilter,
          source: sourceFilter,
        };
      } else {
        return {
          id: item.value,
          enabled: item.enabled,
          order: item.order,
        };
      }
    });

    setPortalsData(dataCollaborator);
  }, [portals]);

  const updateApps = (data) => {
    setPortals({
      ...portals,
      collaborator: {
        ...portals.collaborator,
        config: {
          ...portals.collaborator.config,
          apps: data,
        },
      },
    });
  };
  const appsOnSortEnd = ({ oldIndex, newIndex }) => {
    let tab = arrayMoveImmutable(
      portals.collaborator.config.apps,
      oldIndex,
      newIndex
    );
    tab = tab.map((item, index) => {
      return { ...item, order: index + 1 };
    });
    updateApps(tab);
  };

  const handleScopeChange = (name, value, scope, callback) => {
    if (name === "PUBLIC") {
      callback
        ? callback(value ? [name] : ["PUBLIC"])
        : setScopeFilter(value ? [name] : ["PUBLIC"]);
    } else {
      let tmpValue = value
        ? scope
          ? [...scope.filter((item) => item !== "PUBLIC"), name]
          : [...scopeFilter.filter((item) => item !== "PUBLIC"), name]
        : scope
        ? scope.filter((item) => item !== name)
        : scopeFilter.filter((item) => item !== name);
      if (tmpValue.length === 0) {
        tmpValue = ["PUBLIC"];
      }
      callback ? callback(tmpValue) : setScopeFilter(tmpValue);
    }
  };

  const handleCheckApp = (key, value, item) => {
    const tab = portals.collaborator.config.apps.map((app) => {
      if (app.value === item.value) {
        app.enabled = value;
      }
      return app;
    });
    updateApps(tab);
  };

  const SortableItem = sortableElement(({ type = "collaborator", value }) =>
    value.value === "BLOG" ? (
      <BlogApp
        value={value}
        handleCheckApp={handleCheckApp}
        type={type}
        portals={portals}
        setPortals={setPortals}
        handleScopeChange={handleScopeChange}
        scopeFilter={scopeFilter}
        setScopeFilter={setScopeFilter}
        categoryFilter={categoryFilter}
        setCategoryFilter={setCategoryFilter}
        initCategoryFilter={initCategoryFilter}
        setInitCategoryFilter={setInitCategoryFilter}
        authorTypes={authorTypes}
        authorTypesFilter={authorTypesFilter}
        setAuthorTypesFilter={setAuthorTypesFilter}
        sourceFilter={sourceFilter}
        setSourceFilter={setSourceFilter}
      />
    ) : value.value === "OFFFCOURSE" ? (
      <WatchApp value={value} handleCheckApp={handleCheckApp} type={type} />
    ) : value.value === "NEWSLETTER" ? (
      <NewsletterApp
        value={value}
        handleCheckApp={handleCheckApp}
        type={type}
      />
    ) : (
      <DefaultApp value={value} handleCheckApp={handleCheckApp} type={type} />
    )
  );

  const SortableContainer = sortableContainer(({ children }) => {
    return <ul>{children}</ul>;
  });

  return (
    <div className={styles.box}>
      <div className={styles.box_top}>
        <h3 className={styles.box_title}>
          <span>Portail Collaborateur</span>
          <Switch
            isChecked={portals.collaborator.enabled}
            onChange={(e) => handleCheckPortal("collaborator", e)}
          />
        </h3>
        <p>
          Ce portail est toujours activé. Il vous permet de fonctionner dans un
          environnement ou chacun de vos collaborateurs accèdent et partagent
          les différentes ressources de UA. L’accès est possible pour tous
          collaborateurs qui sont logués.
        </p>
      </div>
      {portals.collaborator.enabled && (
        <div className={styles.box_content}>
          <p>Activer/désactiver ou Modifier l’orde des applications :</p>
          <SortableContainer onSortEnd={(e) => appsOnSortEnd(e)} useDragHandle>
            {portals.collaborator.config.apps.map((value, index) => (
              <SortableItem
                key={`item-${value.value}`}
                index={index}
                value={value}
              />
            ))}
          </SortableContainer>
        </div>
      )}
    </div>
  );
};

export default Collaborator;
