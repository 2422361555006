import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useQuery, useQueryClient } from "react-query";
import Select from "react-select";
import moment from "moment";
import { writeFile, utils } from "xlsx";
import { formatPhoneNumberIntl } from "react-phone-number-input";

import { getMemberships, getUaMemberships } from "../../api";
import _ from "../../i18n";
import { SELECT_STYLES } from "../../config";

import Pagination from "../common/Pagination";
import Loader from "../common/Loader";
import Button from "../common/Button";

import styles from "./ManageMembership.module.scss";

const statusOptions = [
  { label: _("pending"), value: "PENDING" },
  { label: _("paid"), value: "PAID" },
];

const sourceOptions = [
  { label: "UA", value: "UA" },
  { label: "OECCBB", value: "OECCBB" },
];

const currentYear =
  moment().month() == 11 ? moment().year() + 1 : moment().year();

const yearsOptions = [{ value: currentYear, label: currentYear }];

for (let i = currentYear - 1; i >= 2024; i--) {
  yearsOptions.push({ value: i, label: i });
}

const ManageMembership = (props) => {
  const queryClient = useQueryClient();
  const auth = useSelector((state) => state.auth);
  const { token } = auth;
  const [status, setStatus] = useState([statusOptions[1]]);
  const [source, setSource] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [memberships, setMemberShips] = useState(null);
  const [formulas, setFormulas] = useState(null);
  const [formulaFilter, setFormulaFilter] = useState(null);
  const [formulaOptions, setFormulaOptions] = useState([]);
  const [year, setYear] = useState(yearsOptions[0]);
  const [sort, setSort] = useState({
    property: "createdAt",
    dir: "desc",
  });
  const [isFetching, setIsFetching] = useState(false);

  const LIMIT = 20;

  let { isLoading, data } = useQuery(
    [
      "listMemberships",
      token,
      year,
      sort,
      status,
      source,
      currentPage,
      formulaFilter,
    ],
    async () => {
      if (token) {
        if (!memberships) {
          try {
            const response = await getUaMemberships(token);
            let membershipTab = {},
              formulasTab = {},
              formulaOptionsTab = [];
            response.data.data.forEach((item) => {
              membershipTab[item.id] = {
                id: item.id,
                name: item.nameFr,
                hasSlider: item.hasSlider,
                type: item.type,
              };
              item.uaMemberShipFormulas.forEach((formula) => {
                formulasTab[formula.id] = {
                  id: formula.id,
                  name: formula.nameFr,
                  annualPrice: formula.annualPrice,
                };
                formulaOptionsTab.push({
                  value: formula.id,
                  label: formula.nameFr,
                });
              });
            });
            setFormulaOptions(formulaOptionsTab);
            setMemberShips(membershipTab);
            setFormulas(formulasTab);
          } catch (e) {}
        }
        try {
          const formulaIds = formulaFilter
            ? formulaFilter.map((i) => i.value)
            : [];
          const tabStatus = status.map((s) => s.value);
          const response = await getMemberships({
            token,
            status: tabStatus,
            page: currentPage,
            limit: LIMIT,
            sort,
            year: year.value,
            formulaIds,
            source: source ? source.value : null,
            addOnboarding: true,
            addAdhesionList: true,
          });
          return response.data;
        } catch (e) {
          return [];
        }
      }
    },
    { cacheTime: 0 }
  );

  const handleStatusChange = (e) => {
    setStatus(e);
  };

  const handleExport = async () => {
    if (status.length === 0) {
      return null;
    }
    let data = [];
    setIsFetching(true);
    try {
      const formulaIds = formulaFilter ? formulaFilter.map((i) => i.value) : [];
      const tabStatus = status.map((s) => s.value);
      const response = await getMemberships({
        token,
        status: tabStatus,
        page: currentPage,
        limit: 50000,
        sort,
        year: year.value,
        formulaIds,
        source: source ? source.value : null,
        addOnboarding: true,
        addAdhesionList: true,
      });
      data = response.data.data;
    } catch (e) {}

    if (data.length === 0) {
      setIsFetching(false);
      return null;
    }

    const rows = [];
    const columnsWidth = [
      { wch: 25 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 }, //organization
      { wch: 15 }, //Address
      { wch: 15 }, //postalCode
      { wch: 15 }, //city
      { wch: 15 }, //uen
    ];

    const currentYear =
      moment().month() == 11 ? moment().year() + 1 : moment().year();
    const yearsOptions = [currentYear];
    for (let i = currentYear - 1; i >= 2020; i--) {
      yearsOptions.push(i);
    }
    yearsOptions.forEach((i) => {
      columnsWidth.push({ wch: 15 });
    });

    data.forEach((item) => {
      const name = `${item.user.firstName ? item.user.firstName : ""} ${
        item.user.lastName ? item.user.lastName : ""
      }`;
      if (name.length > columnsWidth[0].wch) {
        columnsWidth[0].wch = name.length;
      }
      let formule = [];
      if (item.typeMember === "ASSOCIATE") {
        let tmp = "Associé";
        if (item.parent) {
          tmp +=
            " lié à : " +
            item.parent.user.firstName +
            " " +
            item.parent.user.lastName;
        }
        formule.push(tmp);
      } else {
        formule = item.data.map(
          (i) =>
            memberships[i.membersShip].name + " " + formulas[i.formula].name
        );
      }

      const row = {
        Formule: formule.join(" | "),
        "Prénom / Nom": name,
        Email: item.user.mainEmail ?? "",
        Téléphone: item.user.mainPhone
          ? formatPhoneNumberIntl(item.user.mainPhone)
          : "",
        Statut: _(item.status.toLowerCase()),
        Date: moment(item.createdAt).format("DD/MM/YYYY"),
        "UA Onboarding":
          item.onBoardingStatus == "PENDING"
            ? "En attente de validation"
            : item.onBoardingStatus == "VALIDATED"
            ? "Validé"
            : "-",
        Organisation: item.billingOrganization,
        Adresse: item.billingStreet,
        "Code postal": item.billingPostalCode,
        Ville: item.billingCountry,
        "Num TVA": item.billingCompanyNumber,
      };

      let tmpYears = {};
      yearsOptions.forEach((i) => {
        console.log(item.purchasedYears, i, item.purchasedYears.includes(i));

        tmpYears = {
          ...tmpYears,
          [" " + i]: item?.purchasedYears?.includes(i) ? "Oui" : "Non",
        };
      });
      rows.push({ ...row, ...tmpYears });

      if (row.Email.length > columnsWidth[2].wch) {
        columnsWidth[2].wch = row.Email.length;
      }
      if (row["Téléphone"].length > columnsWidth[3].wch) {
        columnsWidth[3].wch = row["Téléphone"].length;
      }
    });

    const worksheet = utils.json_to_sheet(rows);
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, "Dates");

    /* fix headers */
    utils.sheet_add_aoa(
      worksheet,
      [
        [
          "Formule",
          "Prénom / Nom",
          "Email",
          "Téléphone",
          "Statut",
          "Date",
          "UA Onboarding",
        ],
      ],
      { origin: "A1" }
    );

    /* calculate column width */
    worksheet["!cols"] = columnsWidth;

    /* create an XLSX file and try to save to Presidents.xlsx */
    writeFile(workbook, "Adhesions.xlsx");
    setIsFetching(false);
  };

  return (
    <div className="grid-container">
      {isFetching && <div className="lmask"></div>}
      <h1 className={styles.pageTitle}>
        <span>
          Liste des adhésions OECCBB{" "}
          {!isLoading && data?.nbResult && <span>({data.nbResult})</span>}
        </span>

        <Button
          onClick={handleExport}
          size="sm"
          disabled={isLoading || !data || data?.data?.length === 0}
        >
          <i className="icon-ttp-download" /> Exporter
        </Button>
      </h1>

      <div
        className={`${styles.filterBar} ${
          props.fromTab && styles.filterColumn
        }`}
      >
        <label>Filtrer par:</label>
        <div className={styles.filter}>
          <label>Année</label>
          <Select
            styles={SELECT_STYLES}
            options={yearsOptions}
            isSearchable={false}
            value={year}
            onChange={(e) => setYear(e)}
          />
        </div>
        <div className={styles.filter}>
          <label>Status</label>
          <Select
            isMulti
            styles={SELECT_STYLES}
            options={statusOptions}
            isSearchable={false}
            value={status}
            onChange={handleStatusChange}
          />
        </div>
        <div className={styles.filter}>
          <label>Source</label>
          <Select
            isClearable
            styles={SELECT_STYLES}
            options={sourceOptions}
            isSearchable={false}
            value={source}
            onChange={(e) => setSource(e)}
          />
        </div>
        <div className={styles.filter}>
          <label>Formule</label>
          <div className={styles.formulaFilter}>
            <Select
              isMulti
              styles={SELECT_STYLES}
              options={formulaOptions}
              isSearchable={false}
              value={formulaFilter}
              onChange={(e) => setFormulaFilter(e)}
            />
          </div>
        </div>
      </div>

      <table className={`${styles.table} unstriped`}>
        <thead>
          <tr>
            <th width="40%" className={styles.nowrap}>
              <span>Formule</span>
            </th>
            <th width="20%">Utilisateur</th>
            <th width="20%">Email</th>
            <th>Téléphone</th>
            <th>Statut</th>
            <th>Date</th>
            <th>UA Onboarding</th>
            <th>Adhésions</th>
          </tr>
        </thead>
        <tbody>
          {isLoading ? (
            <tr>
              <td colSpan={6} className={styles.centerCell}>
                <Loader
                  style={{
                    height: "10px",
                  }}
                  color={"#B2BCC6"}
                />
              </td>
            </tr>
          ) : data?.data && data.data.length > 0 ? (
            data.data.map((item) => {
              let txtAssocie = "";
              if (item.typeMember === "ASSOCIATE") {
                txtAssocie = "Associé";
                if (item.parent) {
                  txtAssocie +=
                    " lié à : " +
                    item.parent.user.firstName +
                    " " +
                    item.parent.user.lastName;
                }
              }
              return (
                <tr key={item.id}>
                  <td>
                    {item.typeMember === "ASSOCIATE"
                      ? txtAssocie
                      : item.data.map((row) => (
                          <p className={styles.btmBorder}>
                            {memberships[row.membersShip].name +
                              " " +
                              formulas[row.formula].name}
                          </p>
                        ))}
                  </td>
                  {/* <td>
                    {item.data.map((row) => (
                      <p className={styles.btmBorder}>
                        {formulas[row.formula].name}
                      </p>
                    ))}
                  </td> */}
                  <td>
                    {item.user.firstName} {item.user.lastName}
                  </td>
                  <td>{item.user.mainEmail}</td>
                  <td>{item.user.mainPhone}</td>
                  <td>{_(item.status.toLowerCase())}</td>
                  <td>{moment(item.createdAt).format("DD/MM/YYYY")}</td>
                  <td>
                    {item.onBoardingStatus == "PENDING"
                      ? "En attente de validation"
                      : item.onBoardingStatus == "VALIDATED"
                      ? "Validé"
                      : "-"}
                  </td>
                  <td>
                    {item.purchasedYears ? item.purchasedYears.join(", ") : ""}
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan={6} className={styles.centerCell}>
                Liste vide (aucune donnée ne répond aux filtres)
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {data?.nbResult && (
        <Pagination
          className="pagination-bar"
          currentPage={currentPage}
          totalCount={data.nbResult}
          pageSize={LIMIT}
          onPageChange={(page) => setCurrentPage(page)}
        />
      )}
    </div>
  );
};

export default ManageMembership;
