import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Select from "react-select";
import { ModalConfirm } from "tamtam-components";
import { toast } from "react-toastify";

import Loader from "../../common/Loader";
import Button from "../../common/Button";
import FormInput from "../../common/FormInput";
import _ from "../../../i18n";
import { checkUserByEmail, getUserById, saveCollaborator } from "../../../api";
import { LANGUAGES, SELECT_STYLES } from "../../../config";
import {
  validateEmail,
  validatePhone,
  formatPhone,
  formatHidePhone,
  getRemainAddCollaborator,
} from "../../../utils";
import styles from "./Collaborator.module.scss";

const collaboratorsOptions = [
  { value: "MANAGER", label: "Admin" },
  { value: "OFFICIAL", label: _("OFFICIAL") },
  { value: "EXTERNAL", label: _("EXTERNAL") },
];

const CollaboratorForm = (props) => {
  const auth = useSelector((state) => state.auth);
  const fiduciary = useSelector((state) => state.folder.fiduciary);
  const [email, setEmail] = useState("");
  const [lastname, setLastname] = useState("");
  const [firstname, setFirstname] = useState("");
  const [phone, setPhone] = useState("");
  const [phoneValue, setPhoneValue] = useState("");
  const [roleId, setRoleId] = useState(null);
  const [role, setRole] = useState(collaboratorsOptions[3]);
  const [language, setLanguage] = useState(LANGUAGES[0]);
  const [isSaving, setIsSaving] = useState(false);
  const [isFetchingUser, setIsFetchingUser] = useState(false);
  const [fetchedUser, setFetchedUser] = useState(null);
  const [disableFields, setDisableFields] = useState(false);
  const [isValidForm, setIsValidForm] = useState(false);
  const [showConfirmMail, setShowConfirmMail] = useState(false);
  const [errors, setErrors] = useState({
    email: "",
    lastname: "",
    firstname: "",
    phone: "",
  });
  const [allowAdd, setAllowAdd] = useState(true);

  useEffect(() => {
    if (fiduciary && fiduciary.uaMode === "UNIVERSITY") {
      collaboratorsOptions.forEach((i, idx) => {
        if (i.value === "OFFICIAL") {
          collaboratorsOptions[idx].label = _("student");
        }
      });
    }
  }, [fiduciary]);

  useEffect(() => {
    if (fiduciary && !props.currentItem) {
      const count = getRemainAddCollaborator(
        fiduciary.officeSize,
        props.nbCollaborators
      );
      setAllowAdd(count <= 0 ? false : true);
    } else {
      setAllowAdd(true);
    }
  }, [fiduciary, props.currentItem]);

  useEffect(() => {
    if (props.currentItem) {
      setIsFetchingUser(true);
      getUserById(auth.token, props.currentItem.id)
        .then((resp) => {
          if (resp.data.data.length > 0) {
            setEmail(resp.data.data[0].mainEmail);
            setFirstname(resp.data.data[0].firstName);
            setLastname(resp.data.data[0].lastName);
            if (resp.data.data[0].mainPhone) {
              setPhone(resp.data.data[0].mainPhone);
              setPhoneValue(resp.data.data[0].mainPhone);
            }
            setFetchedUser(resp.data.data[0]);
            setIsFetchingUser(false);

            if (resp.data.data[0].roles) {
              const role = resp.data.data[0].roles.filter(
                (r) => r.organization.id === fiduciary.id
              );
              if (role.length === 1) {
                setRoleId(role[0].id);

                const roleOption = collaboratorsOptions.filter((item) => {
                  if (role[0].type === "LEGAL_REPRESENTATIVE") {
                    return (
                      item.value === role[0].type + "-" + role[0].typeStatus
                    );
                  } else {
                    return item.value === role[0].type;
                  }
                })[0];
                setRole(roleOption);
              }
            }

            // if (resp.data.data[0].role) {
            //   setRoleId(resp.data.data[0].role.id);
            // }

            const lang = LANGUAGES.filter(
              (item) => item.value === resp.data.data[0].language
            )[0];
            setLanguage(lang);
            setIsValidForm(true);
          }
        })
        .catch((e) => {
          setDisableFields(false);
          setIsFetchingUser(false);
          setFetchedUser(null);
        });
    }
  }, [props.currentItem]);

  useEffect(() => {
    if (props.resetForm) {
      handleResetForm();
      props.setResetForm(false);
    }
  }, [props.resetForm]);

  useEffect(() => {
    if (email.length > 0 && firstname.length > 0 && lastname.length > 0) {
      setIsValidForm(
        errors.email || errors.lastname || errors.firstname || errors.phone
          ? false
          : true
      );
    }
  }, [errors]);

  const handleKeyUp = (fieldName, fieldValue) => {
    let value = "";
    if (fieldValue.length === 0) {
      value = _("required_field");
    } else {
      switch (fieldName) {
        case "email":
          if (!validateEmail(fieldValue)) {
            value = _("validate_email");
          }
          break;
        case "phone":
          const result = validatePhone(fieldValue);
          if (!result.valid) {
            value = _("validate_phone");
          }
          break;
        case "lastname":
        case "firstname":
          if (fieldValue.length < 2) {
            value = _("required_2_characters");
          }
          break;
      }
    }
    setErrors({ ...errors, [fieldName]: value });
  };

  const handleEmailBlur = (e) => {
    if (email.length > 0 && validateEmail(email)) {
      if (fetchedUser && fetchedUser.mainEmail === email) {
        return null;
      }
      setIsFetchingUser(true);
      checkUserByEmail(auth.token, email, fiduciary.id)
        .then((resp) => {
          if (resp.data.data.exist) {
            toast.info(_("collaborator_exist"));
            setEmail("");
          } else if (resp.data.data.user) {
            setFirstname(resp.data.data.user.firstName);
            setLastname(resp.data.data.user.lastName);
            if (resp.data.data.user.mainPhone) {
              setPhone(resp.data.data.user.mainPhone);
              setPhoneValue(formatHidePhone(resp.data.data.user.mainPhone));
            }
            setRoleId(null);
            setFetchedUser(resp.data.data.user);
            setDisableFields(true);
            setErrors({
              email: "",
              lastname: "",
              firstname: "",
              phone: "",
            });
            // if (resp.data.data.user.role) {
            //   const role = collaboratorsOptions.filter((item) => {
            //     if (resp.data.data.user.role.type === "LEGAL_REPRESENTATIVE") {
            //       return (
            //         item.value ===
            //         resp.data.data.user.role.type +
            //           "-" +
            //           resp.data.data.user.role.typeStatus
            //       );
            //     } else {
            //       return item.value === resp.data.data.user.role.type;
            //     }
            //   })[0];
            //   setRole(role);
            // }
            const lang = LANGUAGES.filter(
              (item) => item.value === resp.data.data.user.language
            )[0];
            setLanguage(lang);
          }
          setIsFetchingUser(false);
        })
        .catch((e) => {
          setDisableFields(false);
          setIsFetchingUser(false);
          setFetchedUser(null);
          // handleResetForm(false);
          setRoleId(null);
        });
    }
  };

  const handleConfirmUserByEmail = () => {
    setFirstname(fetchedUser.firstName);
    setLastname(fetchedUser.lastName);
    if (fetchedUser.mainPhone) {
      setPhone(fetchedUser.mainPhone);
      setPhoneValue(formatHidePhone(fetchedUser.mainPhone));
    }
    if (fetchedUser.role) {
      if (fetchedUser.role) {
        const role = collaboratorsOptions.filter((item) => {
          if (fetchedUser.role.type === "LEGAL_REPRESENTATIVE") {
            return (
              item.value ===
              fetchedUser.role.type + "-" + fetchedUser.role.typeStatus
            );
          } else {
            return item.value === fetchedUser.role.type;
          }
        })[0];
        setRole(role);
      }
    }
    const lang = LANGUAGES.filter(
      (item) => item.value === fetchedUser.language
    )[0];
    setLanguage(lang);
    setDisableFields(true);
    setShowConfirmMail(false);
    setErrors({
      email: "",
      lastname: "",
      firstname: "",
      phone: "",
    });
  };

  const handlePhoneBlur = (e) => {
    if (phone === phoneValue) {
      setPhone(formatPhone(phone));
      setPhoneValue(formatPhone(phone));
    }
  };

  const handlePhoneKeyUp = (fieldValue) => {
    let value = "";
    if (fieldValue.length > 0) {
      const result = validatePhone(fieldValue);
      if (!result.valid) {
        value = _("validate_phone");
      }
    }
    setErrors({ ...errors, phone: value });
  };

  const validate = () => {
    // let errors = [];
    handleKeyUp("email", email);
    handleKeyUp("lastname", lastname);
    handleKeyUp("firstname", firstname);
    handlePhoneKeyUp(phone);

    return errors.email || errors.lastname || errors.firstname || errors.phone
      ? true
      : false;
  };

  const save = () => {
    let error = validate();
    if (error) {
      return null;
    }

    let type = role.value.split("-");

    const data = {
      email,
      firstname,
      lastname,
      phone,
      language: language.value,
      type: type[0],
      typeStatus: type.length === 2 ? type[1] : null,
      organization: fiduciary.id,
    };

    if (roleId) {
      data.roleId = roleId;
    }

    if (fetchedUser) {
      data.id = fetchedUser.id;
      if (fetchedUser.email && fetchedUser.email.length > 0) {
        const emailResult = fetchedUser.email.filter(
          (i) => i.email === email
        )[0];
        data.emailId = emailResult.id;
        if (!props.currentItem) {
          data.exist = 1;
        }
      }
      if (fetchedUser.phone && fetchedUser.phone.length > 0) {
        data.phoneId = fetchedUser.phone[0].id;
      }
    }

    setIsSaving(true);
    saveCollaborator(auth.token, data)
      .then((resp) => {
        props.afterSave();
        setTimeout(() => {
          setIsSaving(false);
          toast.success(_("successfully_added"));
          handleResetForm();
          if (props.setSideBar) {
            props.setSideBar("DEFAULT");
          }
        }, 1000);
      })
      .catch((e) => {
        if (
          e.response &&
          e.response.data &&
          e.response.data.errors &&
          e.response.data.errors.length > 0
        )
          toast.error(_(e.response.data.errors[0].message));
        else {
          toast.error(_("error"));
        }
        setIsSaving(false);
      });
  };

  const handleResetForm = (resetEmail = true) => {
    if (resetEmail) {
      setEmail("");
    }
    setFirstname("");
    setLastname("");
    setPhone("");
    setPhoneValue("");
    setDisableFields(false);
    setFetchedUser(null);
    setRoleId(null);
    setRole(collaboratorsOptions[3]);
    setLanguage(LANGUAGES[0]);
  };

  return (
    <div className={props.addFormClass ? styles.form : ""}>
      <div
        className={`${styles.form_content} ${
          props.addFormClass && styles.form_content_pad
        } ${isFetchingUser && styles.loading}`}
      >
        {props.setSideBar && (
          <div
            className={styles.close}
            onClick={() => props.setSideBar("DEFAULT")}
          >
            <i className="icon-ttp-close"></i>
          </div>
        )}
        <div className={styles.titles}>
          {fiduciary.uaMode === "UNIVERSITY" ? (
            <>
              <h4>{_("manual_student_title")}</h4>
              <p>{_("manual_student_subtitle")}</p>
            </>
          ) : (
            <>
              <h4>{_("manual_collab_title")}</h4>
              <p>{_("manual_collab_subtitle")}</p>
            </>
          )}
        </div>

        {!props.noLimit && !allowAdd ? (
          <div className={styles.upgrade}>
            <h3>
              <i className="icon-ttp-alert-circle"></i> Limite atteinte !
            </h3>
            <p>Vous avez atteint votre limite de nombre de collaborateurs.</p>

            {fiduciary?.uaMode === "CLASSIC" && (
              <>
                <p>
                  Pour pouvoir ajouter de nouveaux collaborateurs vous pouvez
                  augmenter la taille de votre entreprise
                </p>
                {props.setSideBar && (
                  <Button
                    variant="send"
                    onClick={() => props.setShowUpgradeModal(true)}
                  >
                    Ajouter
                  </Button>
                )}
              </>
            )}
          </div>
        ) : (
          <>
            <FormInput
              name="email"
              required={true}
              label={_("email_address")}
              autocomplete="none"
              error={errors.email}
              value={email}
              onKeyUp={(e) => handleKeyUp(e.target.name, e.target.value)}
              handleBlur={(e) => handleEmailBlur(e)}
              onChange={(e) => setEmail(e.target.value)}
              disabled={props.currentItem}
            />

            <div className="grid-x grid-margin-x">
              <div className="cell small-12 medium-6">
                <FormInput
                  name="lastname"
                  required={true}
                  label={_("lastname")}
                  autocomplete="none"
                  error={errors.lastname}
                  value={lastname}
                  onKeyUp={(e) => handleKeyUp(e.target.name, e.target.value)}
                  onChange={(e) => setLastname(e.target.value)}
                  disabled={disableFields}
                />
              </div>
              <div className="cell small-12 medium-6">
                <FormInput
                  name="firstname"
                  required={true}
                  label={_("firstname")}
                  autocomplete="none"
                  error={errors.firstname}
                  value={firstname}
                  onKeyUp={(e) => handleKeyUp(e.target.name, e.target.value)}
                  onChange={(e) => setFirstname(e.target.value)}
                  disabled={disableFields}
                />
              </div>
            </div>

            <FormInput
              name="phone"
              label={_("mobile_phone_number")}
              placeholder="+32XXXXXXXX"
              autocomplete="none"
              value={phoneValue}
              error={errors.phone}
              onKeyUp={(e) => handlePhoneKeyUp(e.target.value)}
              onChange={(e) => {
                setPhone(e.target.value);
                setPhoneValue(e.target.value);
              }}
              handleBlur={(e) => handlePhoneBlur(e)}
              disabled={disableFields && fetchedUser && fetchedUser.mainPhone}
            />

            <div className="grid-x grid-margin-x">
              <div className="cell small-12 medium-6">
                <div className="ttp-form-group">
                  <label className="ttp-label">{_("role")}</label>
                  <Select
                    styles={SELECT_STYLES}
                    options={collaboratorsOptions}
                    isSearchable={false}
                    value={role}
                    onChange={(e) => setRole(e)}
                  />
                </div>
              </div>
              <div className="cell small-12 medium-6">
                <div className="ttp-form-group">
                  <label className="ttp-label">{_("language")}</label>
                  <Select
                    styles={SELECT_STYLES}
                    options={LANGUAGES}
                    isSearchable={false}
                    value={language}
                    onChange={(e) => setLanguage(e)}
                    isDisabled={disableFields}
                  />
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      {(props.noLimit || allowAdd) && (
        <div className={styles.actions}>
          <Button
            variant="default"
            onClick={() => {
              handleResetForm();
              if (props.setSideBar) {
                props.setSideBar("DEFAULT");
              }
            }}
          >
            {_("cancel")}
          </Button>
          {isSaving ? (
            <Button
              variant="primary"
              style={{ paddingTop: "15px", paddingBottom: "15px" }}
            >
              <Loader
                style={{
                  height: "10px",
                }}
                color={"#fff"}
              />
            </Button>
          ) : (
            <Button disabled={!isValidForm} onClick={() => save()}>
              {props.currentItem ? _("edit") : _("add")}
            </Button>
          )}
        </div>
      )}
      <ModalConfirm
        type="publish"
        isOpen={showConfirmMail}
        onCancel={() => {
          handleResetForm();
          setShowConfirmMail(false);
        }}
        onConfirm={() => handleConfirmUserByEmail()}
        title={_("add_confirm")}
        text={_("text_add_exist_collaborator")}
        labelNo={_("no")}
        labelYes={_("yes")}
        labelError={_("error")}
      />
    </div>
  );
};

export default CollaboratorForm;
